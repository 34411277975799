<template>
  <div class="container">
    <section>
      <v-row class="d-flex align-center justify-center">
        <v-col cols="12" md="7">
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Lugares de lazer e convívio"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                A Praça Cônego Caetano Corrêa, situada à esquerda (norte) do
                adro da Capela de São Bento, era utilizada pelos moradores como
                local de socialização e diversão. Lá ocorriam as festividades
                culturais e religiosas como Reinado e Congado, Festa de Nossa
                Senhora das Mercês e Festa de São Bento.
              </p>
              <p>
                Voltado para a referida praça, encontrava-se o Bar da Sandra,
                muito conhecido pelas coxinhas, além de outras comidas que eram
                preparadas no fogão à lenha. O local era ponto de encontro entre
                moradores da comunidade, assim como de visitantes. Na praça,
                havia mesas e bancos sob a copa das árvores, onde os moradores
                gostavam de se reunir. Ao lado do Bar da Sandra, próximos à
                Capela, encontravam-se dois bancos de pedra paralelos ao tronco
                de uma árvore, onde a Sra. Maria dos Santos gostava de
                descansar. A lama de rejeitos carregou os bancos para cerca de
                20m do local onde se situava, tendo sido encontrado pelos
                moradores após escavação em sua busca, demonstrando o vínculo
                afetivo com o lugar e com seus elementos.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center align-md-end"
        >
          <v-img
            max-width="70%"
            src="../../assets/sobre_cultura_bento_lazer_01.jpeg"
          ></v-img>
          <p style="font-size: 0.5em; width:70%; text-align: center;">
            Vista da Capela de São Bento. À sua esquerda, o Bar da Sandra e à
            frente, a Praça Cônego Caetano Correa. Foto: Andreia Sales / Jornal
            A Sirene, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex align-center justify-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center align-md-start"
        >
          <v-img
            max-width="70%"
            src="../../assets/sobre_cultura_bento_lazer_02.png"
          ></v-img>
          <p style="font-size: 0.5em; width:70%; text-align: center;">
            Fotografia dos bancos de pedra próximos ao Bar da Sandra e à Capela
            de São Bento, onde os moradores gostavam de se reunir, e D. Maria
            descansava. Foto: Acervo Mônica Quintão / Jornal A Sirene.
          </p>
        </v-col>

        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Não só nos bancos da praça as pessoas se encontravam, mas também
                nos que ficavam à frente das casas, no passeio, para onde se
                dirigiam os que terminavam o serviço enquanto aguardavam a
                chegada dos amigos.
              </p>
              <p>
                Além do Bar da Sandra, eram também pontos de encontro o Bar do
                Juca e o Bar do Sobreira, o ginásio poliesportivo e o campo de
                futebol adjacente, como também o campo de futebol atrás da
                edificação do Sr. Sobreira. Estes últimos consistiam em pontos
                de atividades desportivas e de socialização, da mesma forma que
                o campo de futebol localizado a poucos metros da entrada a oeste
                da comunidade. Os campos não se restringiam aos moradores do
                local, pois os torneios recebiam times e habitantes de outras
                comunidades.
              </p>
              <p>
                As cachoeiras e córregos, como o Ouro Fino e o Santarém eram
                locais de lazer e encontro, onde os moradores se banhavam e
                pescavam.
              </p>
              <p style="text-align: center">
                <strong>Autora: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p class="mb-0">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <strong>Referências Bibliográficas</strong>
            </p>
            <p>
              CASTRIOTA, Leonardo Barci. Dossiê de Tombamento de Bento
              Rodrigues. UFMG. Belo Horizonte, 2019. Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento"
                class="pink--text"
                >https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento</a
              >. Acesso em: 30 mar. 2022.
            </p>
            <p>
              PÓLEN Consultoria Patrimônio e Projetos Ltda. Diagnóstico
              preliminar dos bens culturais identificados no território atingido
              em Mariana pelo rompimento da barragem de Fundão. Mariana, 2017.
            </p>
            <p>
              TEIXEIRA, Vanessa Cerqueira. Fé e cultura barroca sob o manto
              mercedário: devoção, religiosidade e vivências a partir da
              irmandade de Nossa Senhora das Mercês de Mariana (1749-1822). 220
              f. 2017. Dissertação (Mestrado em História) — Instituto de
              Ciências Humanas, Universidade Federal de Juiz de Fora Juiz de
              Fora-MG, 2017.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
section {
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
